<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar color="blanco" dark flat>
                <v-spacer></v-spacer>
                <img srcset="../assets/logo.png" alt="" width="70%"> 
                <v-spacer></v-spacer>               
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Usuario"
                    name="login"
                    prepend-icon="mdi-email"
                    type="text"
                    v-model="email"
                    :rules="valemail"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-fingerprint"
                    type="password"
                    v-model="password"
                    :rules="valpassword"
                    @keyup.enter="verificausuario"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-on:click="verificausuario">Login</v-btn>
              </v-card-actions>
              <v-alert
                text
                prominent
                type="error"
                icon="mdi-cloud-alert"
                v-if="size === 0"
              >{{mensaje}}</v-alert>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "HelloWorld",
  data: () => ({
    email: "",
    password: "",
    nombre: "",
    apellidos: "",
    correo: "",
    foto: "",
    size: -1,
    mensaje: '',
    valemail: [value => !!value || "Ingrese su usuario"],
    valpassword: [
      value => !!value || "Ingrese su contraseña",
      value => (value || "").length <= 16 || "Max 16 caracteres"
    ]
  }),
  methods: {
    verificausuario() {
      if (this.email == "" || this.password == "") {
        this.mensaje = 'Complete todos los campos';
      } else {
        const postData = {id: this.email, password: this.password};
        var url = "use/";
        var result = null;
        this.$http.post(url, postData).then(res => {
          if (res.status === 200) {
            result = res.body;            
            if (result.size == 1) {
              localStorage.nombre = result.objeto[0].nombre;
              this.$router.push("/about");
            }else{
              this.size = result.size;
              this.mensaje = result.objeto[0].mensaje;
              console.log(this.mensaje);
            }
          }
        });
      }
    }
  }
};
</script>
